import paths from "~/constants/paths";
import { useCartStore } from "~/store/Features/Checkout/cartStore";
export default defineNuxtRouteMiddleware((to) => {
  const user = useCookie("hs-token");

  if (to.path !== paths.login && !user.value) {
    return navigateTo("/login");
  }

  if (to.path === paths.login && user.value) {
    return navigateTo("/");
  }
  const cartStore = useCartStore();
  if (cartStore.isCartEmpty) {
    return navigateTo("/cart");
  }
});
